<article class="contenedor-principal">
    <div class="d-lg-flex justify-content-between">
        <h2>PROGRAMA</h2>
        <h2>Horario Montevideo, Uruguay (GMT-3)</h2>
    </div>
    <div class="d-flex justify-content-between">
        <button mat-button (click)="dayFunction('0')" class="col p-2 my-3 bg-green"><span class="d-none d-sm-inline">LUNES </span>4/10</button>
        <button mat-button (click)="dayFunction('1')" class="col p-2 my-3 mx-1 m-sm-3 bg-green"><span class="d-none d-sm-inline">MARTES </span>5/10</button>
        <button mat-button (click)="dayFunction('2')" class="col p-2 my-3 bg-green"><span class="d-none d-sm-inline">MIÉRCOLES </span>6/10</button>
        <button mat-button (click)="dayFunction('3')" class="col p-2 my-3 mx-1 m-sm-3 bg-green"><span class="d-none d-sm-inline">JUEVES </span>7/10</button>
        <button mat-button (click)="dayFunction('4')" class="col p-2 my-3 bg-green"><span class="d-none d-sm-inline">VIERNES </span>8/10</button>
    </div>


    <!-- LUNES 4 -->
    <section *ngIf="day=='0'" class="animate__animated animate__fadeIn animate__faster">
        <h3>Lunes 4</h3>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/apertura']">Bienvenida</span>
            </div>
            <div class="w7 d-flex flex-wrap">
                <div class="w1 d-flex-center">10:00</div>
                <div class="w3 d-flex-center">Inicio del congreso</div>
                <div class="w4 d-flex-center">Dr. Federico Battistoni<br>Presidente ALAR</div>
                <div class="w2 d-flex-center">Uruguay</div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/apertura']">Apertura</span>
            </div>
            <div class="w7 d-flex">
                <div class="w1 d-flex-center">10:30</div>
                <div class="w3 d-flex-center">Conferencia de apertura</div>
                <div class="w4 d-flex-center">Dra. Mariangela Hungría</div>
                <div class="w2 d-flex-center">Brasil</div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/mesas-redondas/mujeres-en-la-ciencia']">Mujeres en la ciencia</span>
            </div>
            <div class="w7 d-flex">
                <div class="w1 d-flex-center">12:00</div>
                <div class="w3 d-flex-center">Mesa redonda</div>
                <div class="w58 d-flex flex-column">
                    <div class="d-flex green-sm">
                        <div class="w72">Dra. Estela Castillo</div>
                        <div class="d-flex-center">Uruguay</div>
                    </div>
                    <div class="d-flex green-sm">
                        <div class="w72">Dra. Mariangela Hungría</div>
                        <div class="d-flex-center">Brasil</div>
                    </div>
                    <div class="d-flex">
                        <div class="w72 pr">Dra. Esperanza Martinez</div>
                        <div class="d-flex-center">México</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s">
            </div>
            <div class="w8">14:00</div>
            <div>Puntos de encuentro</div>
        </div>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link link" [routerLink]="['/sesiones-de-trabajo/microbioma-agrícola']">El microbioma agrícola</span>
            </div>
            <div class="w7 d-flex flex-column">
                <div class="d-flex green-sm">
                    <div class="w1">15:00</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dra. Kornelia Smalla</div>
                    <div class="w2">Alemania</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">15:45</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dr. Jorge L. Mazza Rodrigues</div>
                    <div class="w2">Brasil</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">16:30</div>
                    <div>Póster talk</div>
                </div>
                <div class="d-flex">
                    <div class="w1">17:00</div>
                    <div>Diálogos de investigación</div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s">
            </div>
            <div class="w8">18:00</div>
            <div>Sesión de posters</div>
        </div>
    </section>

    <!-- MARTES 5 -->
    <section *ngIf="day=='1'" class="animate__animated animate__fadeIn animate__faster">
        <h3>Martes 5</h3>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/sesiones-de-trabajo/promoción-indirecta-del-crecimiento-vegetal']">Promoción indirecta del crecimiento vegetal</span>
            </div>
            <div class="w7 d-flex flex-column">
                <div class="d-flex green-sm">
                    <div class="w1">10:00</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dr. Jeroen Wagemans</div>
                    <div class="w2">Bélgica</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">10:45</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dra. Alexandra Stoll</div>
                    <div class="w2">Chile</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">11:30</div>
                    <div>Póster talk</div>
                </div>
                <div class="d-flex">
                    <div class="w1">12:00</div>
                    <div>Diálogos de investigación</div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s"></div>
            <div class="w8">14:00</div>
            <div>Puntos de encuentro</div>
        </div>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/sesiones-de-trabajo/promoción-directa-del-crecimiento-vegetal']">Promoción directa del crecimiento vegetal</span>
            </div>
            <div class="w7 d-flex flex-column">
                <div class="d-flex green-sm">
                    <div class="w1">15:00</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dr. Euan James</div>
                    <div class="w2">Inglaterra</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">15:45</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dr. Agustin Grimoldi</div>
                    <div class="w2">Argentina</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">16:30</div>
                    <div>Póster talk</div>
                </div>
                <div class="d-flex">
                    <div class="w1">17:00</div>
                    <div>Diálogos de investigación</div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s"></div>
            <div class="w8">18:00</div>
            <div>Sesión de posters</div>
        </div>
    </section>

    <!-- MIERCOLES 6 -->
    <section *ngIf="day=='2'" class="animate__animated animate__fadeIn animate__faster">
        <h3>Miercoles 6</h3>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/sesiones-de-trabajo/evolución-y-diversidad-de-mpcv']">Evolución y diversidad de microorganismos promotores de crecimiento vegetal</span>
            </div>
            <div class="w7 d-flex flex-column">
                <div class="d-flex green-sm">
                    <div class="w1">10:00</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dr. Massimiliano Cardinale</div>
                    <div class="w2">Italia</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">10:45</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dra. Laila Pamela  Martínez</div>
                    <div class="w2">México</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">11:30</div>
                    <div>Póster talk</div>
                </div>
                <div class="d-flex">
                    <div class="w1">12:00</div>
                    <div>Diálogos de investigación</div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s"></div>
            <div class="w8">14:00</div>
            <div>Puntos de encuentro</div>
        </div>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/sesiones-de-trabajo/el-holobionte-planta']">El Holobionte Planta</span>
            </div>
            <div class="w7 d-flex flex-column">
                <div class="d-flex green-sm">
                    <div class="w1">15:00</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dr. Pablo Hardoim</div>
                    <div class="w2">Brasil</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">15:45</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dr. Emanuel de Souza</div>
                    <div class="w2">Brasil</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">16:30</div>
                    <div>Póster talk</div>
                </div>
                <div class="d-flex">
                    <div class="w1">17:00</div>
                    <div>Diálogos de investigación</div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s"></div>
            <div class="w8">18:00</div>
            <div>Sesión de posters</div>
        </div>
    </section>

    <!-- JUEVES 7 -->
    <section *ngIf="day=='3'" class="animate__animated animate__fadeIn animate__faster">
        <h3>Jueves 7</h3>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/sesiones-de-trabajo/conservación-y-manejo-microbiano']">Conservación y manejo microbiano en sistemas naturales y agroecosistemas</span>
            </div>
            <div class="w7 d-flex flex-column">
                <div class="d-flex green-sm">
                    <div class="w1">10:00</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dra. Marina Sánchez</div>
                    <div class="w2">Colombia</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">10:45</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dra. Alda Rodríguez</div>
                    <div class="w2">Uruguay</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">11:30</div>
                    <div>Póster talk</div>
                </div>
                <div class="d-flex">
                    <div class="w1">12:00</div>
                    <div>Diálogos de investigación</div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s">
            </div>
            <div class="w8">14:00</div>
            <div>Puntos de encuentro</div>
        </div>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/sesiones-de-trabajo/del-laboratorio-al-campo']">Del laboratorio al campo</span>
            </div>
            <div class="w7 d-flex flex-column">
                <div class="d-flex green-sm">
                    <div class="w1">15:00</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dra. C. María Caridad Nápoles</div>
                    <div class="w2">Cuba</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">15:45</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dra. Paula Aguilera</div>
                    <div class="w2">Chile</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">16:30</div>
                    <div>Póster talk</div>
                </div>
                <div class="d-flex">
                    <div class="w1">17:00</div>
                    <div>Diálogos de investigación</div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s"></div>
            <div class="w8">18:00</div>
            <div>Sesión de posters</div>
        </div>
    </section>

    <!-- VIERNES 8 -->
    <section *ngIf="day=='4'" class="animate__animated animate__fadeIn animate__faster">
        <h3>Viernes 8</h3>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/sesiones-de-trabajo/legislación-sobre-productos-microbianos']">Legislación sobre registro y empleo de productos microbianos</span>
            </div>
            <div class="w7 d-flex flex-column">
                <div class="d-flex green-sm">
                    <div class="w1">10:00</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Mag.Bio. Romina Almesía</div>
                    <div class="w2">Chile</div>
                </div>
                <div class="d-flex green-sm">
                    <div class="w1">10:45</div>
                    <div class="w3">Conferencia magistral</div>
                    <div class="w4">Dr. Jerri Zilli</div>
                    <div class="w2">Brasil</div>
                </div>
                <div class="d-flex">
                    <div class="w1">11:30</div>
                    <div>Póster talk</div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/mesas-redondas/bioinsumos']">Actores involucrados en el desarrollo y aplicación de bioinsumos: ¿es posible un modelo alternativo de producción?</span>
            </div>
            <div class="w7 d-flex">
                <div class="w1 d-flex-center">12:00</div>
                <div class="w3 d-flex-center">Mesa redonda</div>
                <div class="w58 d-flex flex-column">
                    <div class="d-flex green-sm">
                        <div class="w72">Ing. Pedro Lage<br>Empresa Lage y Cía (Lallemand)</div>
                        <div class="d-flex-center">Uruguay</div>
                    </div>
                    <div class="d-flex green-sm">
                        <div class="w72 pr">Sr. Tomás Pedro Krotsch<br>Instituto Interamericano de Cooperación para la Agricultura-IICA</div>
                        <div class="d-flex-center">Argentina</div>
                    </div>
                    <div class="d-flex green-sm">
                        <div class="w72 pr">Sr. Solon Cordeiro de Araujo<br>Asociación Nacional de Productores e Importadores de Inoculantes-ANPII</div>
                        <div class="d-flex-center">Brasil</div>
                    </div>
                    <div class="d-flex green-sm">
                        <div class="w72 pr">Dra. Maribel Parada<br>Red Chilena de Bioinsumos</div>
                        <div class="d-flex-center">Chile</div>
                    </div>
                    <div class="d-flex">
                        <div class="w72 pr">Sr. Roberto Rapela<br>Cámara Argentina de Bioinsumos-CABIO</div>
                        <div class="d-flex-center">Argentina</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s">
                <span class="pointer link">Asamblea ALAR</span>
            </div>
            <div class="w8">15:00</div>
            <div>Asamblea ALAR</div>
        </div>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/clausura']">Clausura</span>
            </div>
            <div class="w7 d-flex">
                <div class="w1 d-flex-center">17:00</div>
                <div class="w3 d-flex-center">Conferencia de clausura</div>
                <div class="w4 d-flex-center">Dra. Esperanza Martinez</div>
                <div class="w2 d-flex-center">México</div>
            </div>
        </div>
        <div class="green-lg">
            <div class="w3s d-flex-center">
                <span class="pointer link" [routerLink]="['/clausura']">Saludo final</span>
            </div>
            <div class="w7 d-flex">
                <div class="w1 d-flex-center">18:00</div>
                <div class="w3 d-flex-center">Cierre del congreso</div>
                <div class="w4 d-flex-center">Dr. Federico Battistoni<br>Presidente ALAR</div>
                <div class="w2 d-flex-center">Uruguay</div>
            </div>
        </div>
    </section>
</article>

<div class="w-lg-75 mx-auto my-5 bg-green p-2">
    <img class="img-fluid w-100" src="/assets/img/programa-1.jpg" alt="Programa">
</div>
