<!-- PROGRESSBAR -->
<ul id="progressbar" class="pt-5 px-0">
    <li class="active" id="account"><strong>Cuenta</strong></li>
    <li class="active" id="personal"><strong>Información Personal</strong></li>
    <li class="active" id="postular"><strong>Postular trabajo</strong></li>
    <li class="active" id="payment"><strong>Pago</strong></li>
    <li class="active" id="confirm"><strong>Finalizar</strong></li>
</ul>
<div class="my-5 p-3 p-md-5">
    <!-- ALL USERS -->
    <div class="text-center p-3 p-md-5 bg-white">
        <h4>¡Felicitaciones!</h4>
        <p>Su inscripicón a RELAR-PGPR 2021 se ha completado correctamente.</p>
        <div class="d-flex justify-content-center">
            <button mat-raised-button color="primary" class="min-width-25 mb-3 mx-3" type="button" (click)="volverAlHome()">
                Volver al inicio
            </button>
        </div>
    </div>
</div>
