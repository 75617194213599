<header>
  <!-- HEADER 
  <div class="d-none d-sm-block">
    <div class="header">
      <div class="rectangle-1"></div>
      <div class="rectangle-2"></div>
      <div class="circle"></div>
      <div class="title">RELAR PGPR 2021</div>
      <div class="sub-title">4&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8 OCTUBRE UY</div>
      <hr class="date-line">

      <div class="serie">
        <p><b>XXX</b> REUNIÓN LATINOAMERICANA DE RIZOBIOLOGÍA</p>
        <p><b>V</b> CONFERENCIA LATINOAMERICANA DE MICROORGANISMOS <br> PROMOTORES DEL CRECIMIENTO VEGETAL</p>
      </div>

      <div class="virtual">MODALIDAD VIRTUAL</div>
    </div>
  </div>
  -->

  <div class="d-none d-md-block">
    <div class=" d-flex flex-column justify-content-center">
      <img class="img-fluid" src="/assets/img/header.png" alt="Header RELAR-PGPR">
    </div>
  </div>

  <div class="d-md-none">
    <img class="img-fluid" src="/assets/img/portada-sm.jpg" alt="Portada RELAR PGPR">
  </div>
</header>

<!-- TEXTOS LOCOS -->
<div class="d-none d-sm-block letras-container mb-4">
  <div class="bg-texto-1"></div>
</div>
