<section>
  <div class="width-lg-90">
    <div class="d-flex">
      <div class="col-8 display-flex">
        <div class="imgA"><img class="img-fluid" src="/assets/img/frente-4a.jpg" alt="Postertalk"></div>
        <div class="imgB"><img class="img-fluid" src="/assets/img/frente-4b.jpg" alt="Imagen"></div>
        <div class="imgD"><img class="img-fluid" src="/assets/img/frente-4d.jpg" alt="Posterhall"></div>
        <div class="imgE"><img class="img-fluid" src="/assets/img/frente-4e.jpg" alt="Conferencias Magistrales"></div>
      </div>
      <div class="col-4 display-flex">
        <div class="imgC"><img class="img-fluid" src="/assets/img/frente-4c.jpg" alt="Sesión de pósters"></div>
      </div>
    </div>
    <div class="d-flex display-flex">
      <div class="col-4">
        <div class="imgF"><img class="img-fluid" src="/assets/img/frente-4f.jpg" alt="Mesas Redondas"></div>
      </div>
      <div class="col-8 display-flex">
        <div class="imgG"><img class="img-fluid" src="/assets/img/frente-4g.jpg" alt="Diálogos de investigación"></div>
        <div class="imgH"><img class="img-fluid" src="/assets/img/frente-4h.jpg" alt="Puntos de encuentro"></div>
      </div>
    </div>
  </div>
</section>
