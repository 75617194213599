<section class="texto-01 mt-5">
  <div class="width-lg-90 d-flex texto-01">
    <div class="frente-a0 align-self-center">
      <img class="img-fluid w-100" src="/assets/img/frente-a0.jpg" alt="Introducción">
    </div>
    <div class="frente-a1 align-self-start">
      <img class="img-fluid w-100" src="/assets/img/frente-a1.jpg" alt="Introducción">
    </div>
    <div class="frente-a2 align-self-end mb-5">
      <img class="img-fluid w-100" src="/assets/img/frente-a2.jpg" alt="Introducción">
    </div>
  </div>
</section>
