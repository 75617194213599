<!-- ACERCA DE ALAR -->
<h2 class="visually-hidden">ACERCA DE ALAR</h2>
<div class="letras-container mt-5 pt-5 mb-2">
  <div class="bg-texto-3"></div>
</div>

<div class="letras-container mb-5">
  <div class="bg-texto-4"></div>
</div>

<section class="pb-5 width-lg-90 d-flex justify-content-between">
  <div class="col-5 margin-1">
    <div class="bg-grey p-3">
      <div class="texto-1">
        <p>La Asociación Latinoamericana de Rizobiología (ALAR) es una asociación científica sin fines de lucro cuyo objetivo es promover las actividades relacionadas al estudio y aplicación de microorganismos promotores del crecimiento vegetal (MPCV) en los países de América Latina y el Caribe.</p>
        <p>Su fundación se concreta durante la primera Reunión de la ALAR (RELAR) en 1964, en Montevideo-Uruguay, formalizándose la misma durante la X RELAR en 1980, Maracay-Venezuela.</p>
      </div>
      <div class="text-end"><i class="fas fa-circle"></i></div>
    </div>
  </div>
  <div class="col-7 margin-2">
    <div class="bg-grey p-3 margin-3">
      <div class="texto-2 text-end">
        <p>La estructura organizativa actual está formada por una Secretaría Ejecutiva con sede en Montevideo, Uruguay y un Presidente y Vice-Presidente que alternan entre los integrantes de la Asociación.</p>
        <p>Una de las principales actividades ha sido la coordinación de las Reuniones Latinoamericanas de Rizobiología (RELAR) cuyo objetivo es mantener un ámbito de actualización e intercambio de experiencias y conocimientos sobre los aspectos básicos y aplicados del uso de MPCV. Con cerca de 30 reuniones realizadas, la RELAR se ha transformado en un foro internacional significativo y en una excelente oportunidad para que los investigadores Latinoamericanos generen redes de trabajo.</p>
      </div>
      <div><i class="fas fa-circle"></i></div>
    </div>
    <div class="bg-grey p-3 margin-4 mt-5">
      <div class="texto-3 text-end">
        <p>En esta edición 2021, la XXX RELAR se hará conjuntamente con el V Congreso Latinoamericano de Microorganismos Promotores del Crecimiento Vegetal (PGPR), con el fin de unificar esfuerzos.</p>
      </div>
      <div><i class="fas fa-circle"></i></div>
    </div>
  </div>
</section>


<!-- ACERCA DE PGPR -->
<h2 class="visually-hidden">ACERCA DE PGPR</h2>
<div class="letras-container mt-3 mb-2">
  <div class="bg-texto-3"></div>
</div>

<div class="letras-container mb-5">
  <div class="bg-texto-7"></div>
</div>

<section class="pb-5 mb-5 width-lg-90 d-flex justify-content-between">
  <div class="col-5 margin-1">
    <div class="bg-grey p-3">
      <div class="texto-1">
        <p>Las bacterias que habitan la rizosfera y promueven el crecimiento de las plantas se conocen como PGPR, sigla tomada del inglés que hace referencia a plant growth-promoting rhizobacteria.</p>
        <p>Comprender las bases mecanísticas relacionadas con su capacidad de promover el crecimiento ha tenido relevancia científica y económica directa ya que tales microorganismos se han utilizado como bioinsumos para aumentar el rendimiento de numerosos cultivos en las últimas décadas en todo el mundo. En particular, un número significativo de empresas desarrollaron y comercializaron sus productos en países de Latinoamérica lo que generó un mayor nivel de interacción.</p>
      </div>
      <div class="text-end"><i class="fas fa-circle"></i></div>
    </div>
  </div>
  <div class="col-7 margin-2">
    <div class="bg-grey p-3 margin-4">
      <div class="texto-2">
        <p>Este proceso ha motivado a la comunidad científica a estudiar en detalle el impacto de éstos microorganismos en la interacción planta-bacteria y su rol en la configuración de la comunidad microbiana de la rizosfera.</p>
        <p>Con estos intereses como guía, desde 1987 se desarrolla cada 3 años el Taller Internacional PGPR. En coincidencia con la edición 2012 desarrollada en Colombia y conscientes de la riqueza de recursos microbiológicos y de la diversidad de cultivos de nuestro continente, se gestó la rama Latinoamericana del Taller PGPR apoyada en una demanda regional de intercambio de información básica y aplicada sobre la biología del PGPR y sus aplicaciones.</p>
      </div>
      <div><i class="fas fa-circle"></i></div>
    </div>
    <div class="bg-grey p-3 margin-3 mt-5">
      <div class="texto-3"><i class="fas fa-circle"></i></div>
      <div class="texto-3">
        <p>Así, Quirama (Medellín, Colombia); La Falda (Córdoba, Argentina); Foz de Iguazú (Paraná, Brasil) y Pucón (Araucanía, Chile) fueron sedes de PGPR-Lat en 2014, 2016 y 2018 respectivamente. Luego de la edición 2018 del evento se decidió la realización conjunta del congreso RELAR y el PGPR-Lat, lo que ha quedado plasmado en esta actividad.</p>
      </div>
    </div>
  </div>
</section>