<section>
    <!-- TITULO -->
    <div class="py-5 px-3 px-md-5 text-white" [routerLink]="['/admin']">
        <h2>ADMINISTRADOR</h2>
    </div>

    <!-- MENU -->
    <nav>
        <ul class="d-md-flex justify-content-center m-0">
            <li class="border-lg-2px" [matMenuTriggerFor]="menu">Actualizar</li>
            <!--<li class="border-sm-2px" [routerLink]="['/admin/trabajos']">Trabajos</li>
            <li class="border-lg-2px" [routerLink]="['/admin/usuarios']">usuarios</li>-->
        </ul>
        
        <mat-menu #menu="matMenu">
            <ul class="py-2 mt-2">
                <li class="li" [routerLink]="['/admin/conferencista']">Conferencista</li>
                <!--<li class="li" [routerLink]="['/admin/panelista']">Panelista</li>
                <li class="li" [routerLink]="['/admin/actualizar-sesion-de-trabajo']">Sesión de trabajo</li>
                <li class="li" [routerLink]="['/admin/actualizar-mesa-redonda']">Mesa Redonda</li>-->
            </ul>
        </mat-menu>
    </nav>
    <router-outlet></router-outlet>
</section>