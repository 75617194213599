<div class="bg-white p-3 p-md-5 m-md-5" *ngIf="loaded">
    <h3>Editar Trabajo</h3>
    <hr>
    <ul>
        <li>Se aceptan resúmenes en idioma español, portugués o inglés.</li>
        <li>Todos los trabajos aceptados participarán en el PÓSTER HALL y en las SESIONES DE PÓSTERS. y, aquellos seleccionados por el comité científico, tendrán la oportunidad de ser expuestos en los POSTER TALK y/o en los DIÁLOGOS DE INVESTIGACIÓN.</li>
        <li>Todos los trabajos aceptados tendrán la oportunidad de ser publicados, en formato de artículo científico, en la Special Issue (July-Sept 2022) Plant growth-promoting microorganisms in the New Era: from Ecology to Biotechnology in America, de la revista Environmental Sustainability. Editorial Springer. <a href="https://www.springer.com/journal/42398/updates/19148470">Más información aquí</a></li>
    </ul>
    <hr>
    <!-- FORM -->
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="save()">
        <!-- TEMA -->
        <mat-form-field appearance="fill">
            <mat-label>Seleccione la sesión a la que postula</mat-label>
            <mat-select formControlName="tema">
                <mat-option value="1">1. Evolución y diversidad de MPCV</mat-option>
                <mat-option value="2">2. Promoción indirecta del crecimiento vegetal</mat-option>
                <mat-option value="3">3. Promoción directa del crecimiento vegetal</mat-option>
                <mat-option value="4">4. El microbioma agrícola </mat-option>
                <mat-option value="5">5. El holobionte planta</mat-option>
                <mat-option value="6">6. Conservación y manejo del microbioma en sistemas naturales y
                    agroecosistemas</mat-option>
                <mat-option value="7">7. Del laboratorio al campo</mat-option>
                <mat-option value="8">8. Legislación sobre registro y empleo de productos microbiano</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="d-md-flex">
            <!-- TÍTULO -->
            <mat-form-field appearance="fill">
                <mat-label>Título</mat-label>
                <input type="text" matInput formControlName="titulo">
                <mat-error *ngIf="isInvalid('titulo')">El campo título es obligatorio</mat-error>
            </mat-form-field>

            <!-- AUTORES -->
            <mat-form-field appearance="fill" class="ml-md">
                <mat-label>Autores</mat-label>
                <input type="text" matInput formControlName="autores">
                <mat-error *ngIf="isInvalid('autores')">El campo autores es obligatorio</mat-error>
            </mat-form-field>
        </div>


        <div class="d-md-flex">
            <!-- INSTITUCIÓN -->
            <mat-form-field appearance="fill">
                <mat-label>Institución/ Afiliación</mat-label>
                <input type="text" matInput formControlName="institucion">
                <mat-error *ngIf="isInvalid('institucion')">El campo afilición/ institución es obligatorio
                </mat-error>
            </mat-form-field>

            <!-- EXPONE -->
            <mat-form-field appearance="fill" class="ml-md">
                <mat-label>Autor/ Presentador</mat-label>
                <input type="text" matInput formControlName="expone">
                <mat-error *ngIf="isInvalid('expone')">El campo presentador es obligatorio</mat-error>
            </mat-form-field>
        </div>

        <!-- RESUEMEN -->
        <mat-form-field appearance="fill">
            <mat-label>Resumen - Máximo 300 palabras</mat-label>
            <textarea matInput formControlName="resumen"></textarea>
            <mat-error *ngIf="isInvalid('institucion')">El campo resumen es obligatorio</mat-error>
        </mat-form-field>

        <!-- SUBMIT -->
        <div class="d-flex justify-content-end">
            <button mat-raised-button color="primary" class="w-25 mb-3 mx-3" type="submit">
                Actualizar
            </button>
        </div>
    </form>
</div>