import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-fechas-importantes',
  templateUrl: './fechas-importantes.component.html',
  styleUrls: ['./fechas-importantes.component.css']
})
export class FechasImportantesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
