<!-- PROGRESSBAR -->
<ul id="progressbar" class="pt-5 px-0">
    <li class="active" [routerLink]="['/registrarse-1']" id="account"><strong>Cuenta</strong></li>
    <li class="active" id="personal"><strong>Información Personal</strong></li>
    <li id="postular"><strong>Postular trabajo</strong></li>
    <li id="payment"><strong>Pago</strong></li>
    <li id="confirm"><strong>Finalizar</strong></li>
</ul>

<div class="bg-white w-lg-75 p-3 p-md-5 m-md-5 mx-lg-auto">
    <h3>Información Personal</h3>
    <hr>
    
    <form autocomplete="off" [formGroup]="form" (ngSubmit)="save()">
    
        <!-- NAME -->
        <div class="d-md-flex">
            <mat-form-field appearance="fill">
                <mat-label>Nombre</mat-label>
                <input type="text" matInput formControlName="name">
                <mat-error *ngIf="isInvalid('name')">El campo nombre es obligatorio</mat-error>
            </mat-form-field>
    
            <!-- LASTNAME -->
            <mat-form-field appearance="fill" class="ml-md">
                <mat-label>Apellido</mat-label>
                <input type="text" matInput formControlName="lastname">
                <mat-error *ngIf="isInvalid('lastname')">El campo apellido es obligatorio</mat-error>
            </mat-form-field>
        </div>
    
        <!-- MAIL -->
        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input type="text" matInput formControlName="mail">
            <mat-error *ngIf="isInvalid('mail')">Ingrese un email válido</mat-error>
        </mat-form-field>
    
        <div class="d-md-flex">
            <!-- GÉNERO -->
            <mat-form-field appearance="fill">
                <mat-label>Género</mat-label>
                <mat-select formControlName="sexo">
                    <mat-option value="H">Hombre</mat-option>
                    <mat-option value="M">Mujer</mat-option>
                    <mat-option value="HT">Hombre Trans</mat-option>
                    <mat-option value="MT">Mujer Trans</mat-option>
                    <mat-option value="O">Otro</mat-option>
                </mat-select>
            </mat-form-field>
    
    
            <!-- TELÉFONO -->
            <mat-form-field appearance="fill" class="ml-md">
                <mat-label>Teléfono</mat-label>
                <input type="text" matInput formControlName="telefono">
                <mat-error *ngIf="isInvalid('telefono')">Ingrese un teléfono válido</mat-error>
            </mat-form-field>
        </div>
    
        <br>
    
        <div class="d-md-flex">
            <!-- FORMACION -->
            <mat-form-field appearance="fill">
                <mat-label>Formación</mat-label>
                <mat-select formControlName="formacion">
                    <mat-option value="EG">Estudiante de grado</mat-option>
                    <mat-option value="U">Grado universitario</mat-option>
                    <mat-option value="EM">Estudiante de maestría</mat-option>
                    <mat-option value="M">Magister</mat-option>
                    <mat-option value="ED">Estudiante de doctorado</mat-option>
                    <mat-option value="D">Doctor/a</mat-option>
                    <mat-option value="O">Otro</mat-option>
              </mat-select>
            </mat-form-field>
        
            <!-- CATEGORÍA -->
            <mat-form-field appearance="fill" class="ml-md">
                <mat-label>Categoría</mat-label>
                <mat-select formControlName="categoria" #categoria>
                    <mat-option value="0">Investigador/ Profesional socio ALAR</mat-option>
                    <mat-option value="1">Investigador/ Profesional</mat-option>
                    <mat-option value="2">Estudiante socio ALAR</mat-option>
                    <mat-option value="3">Estudiante</mat-option>
                    <mat-option value="4">Productor</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
    
        <div class="d-md-flex" *ngIf="categoria.value!='6'">
            <!-- INSTITUCIÓN -->
            <mat-form-field appearance="fill">
                <mat-label>Institución</mat-label>
                <input type="text" matInput formControlName="institucion">
                <mat-error *ngIf="isInvalid('institucion')">El campo institución es obligatorio</mat-error>
            </mat-form-field>
        
            <!-- SECCIÓN -->
            <mat-form-field appearance="fill" class="ml-md">
                <mat-label>Sección/ Departamento</mat-label>
                <input type="text" matInput formControlName="seccion">
                <mat-error *ngIf="isInvalid('seccion')">El campo sección es obligatorio</mat-error>
            </mat-form-field>
        </div>
    
        <div class="d-md-flex">
            <!-- CIUDAD -->
            <mat-form-field appearance="fill">
                <mat-label>Ciudad</mat-label>
                <input type="text" matInput formControlName="ciudad">
                <mat-error *ngIf="isInvalid('seccion')">El campo ciudad es obligatorio</mat-error>
            </mat-form-field>
    
            <!-- PAIS -->
            <mat-form-field appearance="fill" class="ml-md">
                <mat-label>País</mat-label>
                <mat-select formControlName="pais">
                    <mat-option value="UY">{{ 'COUNTRIES.UY' | translate }}</mat-option>
                    <hr class="m-0 p-0">
                    <mat-option *ngFor="let pais of data.paises | orderTranslate" value="pais">{{ 'COUNTRIES.'+pais | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    
        <div class="d-md-flex">
            <!-- DOCUMENTO -->
            <mat-form-field appearance="fill">
                <mat-label>N° de Documento</mat-label>
                <input type="text" matInput formControlName="dni">
                <mat-error *ngIf="isInvalid('seccion')">Ingrese un documento válido</mat-error>
            </mat-form-field>
        
            <!-- FECHA DE NACIMIENTO -->
            <mat-form-field appearance="fill" class="ml-md">
                <mat-label>Fecha de nacimiento</mat-label>
                <input type="text" placeholder="DD/MM/AAAA" matInput formControlName="fechaDeNacimiento">
                <mat-error *ngIf="isInvalid('seccion')">Ingrese una fecha válida</mat-error>
            </mat-form-field>
        </div>
    
        <!-- SUBMIT -->
        <div class="d-flex justify-content-end">
            <button mat-raised-button color="primary" class="min-width-25 mb-3 mx-3" type="submit">
                Continuar
            </button>
        </div>
    </form>
</div>
