<article>
    <div class="letras-container my-5">
        <div class="bg-texto-5"></div>
    </div>
    <div class="m-md-5">

        <section class="row mt-0">
            <div class="col-12 col-md-4 col-lg-3">
                <img class="img-fluid" src="/assets/img/noti-4.jpg" alt="Placa de noticia">
            </div>
            <div class="col-12 col-md-8 mt-3">
                <h3>Inscripción PEDECIBA</h3>
                <hr>
                <p>Investigadores y estudiantes de PEDECIBA (Biología o Químimca) pueden abonar el costo de inscripción por medio de la alícuota PEDECIBA, enviando el siguiente formulario: <a href="https://firebasestorage.googleapis.com/v0/b/relar-pgpr.appspot.com/o/FormularioPagoRELAR_PGPR_Pedeciba.odt?alt=media&amp;token=007214a8-03f6-43e4-85ff-dbee06824e0b" target="_blank" rel="Formulario PEDECIBA">link formulario</a> a PEDECIBA Biologia: <a href="mailto:presupuestobiologia@pedeciba.edu.uy">presupuestobiologia@pedeciba.edu.uy</a> con copia a <a href="mailto:secretaria.alar@gmail.com">secretaria.alar@gmail.com</a>  / PEDECIBA Química: <a href="mailto:gabig@fq.edu.uy">gabig@fq.edu.uy</a> con copia a <a href="mailto:secretaria.alar@gmail.com">secretaria.alar@gmail.com</a>.</p>
                <p>Toda persona que haya realizado el pago de inscripción a través de la alícuota PEDECIBA deberá solicitar su código de inscripción a <a href="mailto:secretaria.alar@gmail.com">secretaria.alar@gmail.com</a></p>
            </div>
        </section>

        <section class="row mt-0">
            <div class="col-12 col-md-4 col-lg-3">
                <img class="img-fluid" src="/assets/img/noti-2.jpg" alt="Placa de noticia">
            </div>
            <div class="col-12 col-md-8 mt-3">
                <h3>ALAR</h3>
                <hr>
                <p>El Comité Organizador Científico Nacional decidió que la cuota bianual de ALAR esté incluida dentro del costo de inscripción. Asimismo establece una amnistía para todas aquellas socias y socios que participen de la RELAR-PGPR edición 2021.</p>
                <p>Si quieres ser socia o socio ALAR debes enviar un mail a <a href="mailto:secretaria.alar@gmail.com">secretaria.alar@gmail.com</a> asunto: ALTA SOCIO/A ALAR describiendo para CATEGORÍA INVESTIGADOR/A: sus últimas actividades científicas más relevantes (máximo 5 líneas) para CATEGORÍA ESTUDIANTE: Institución educativa a la que pertenece y nivel de estudios alcanzados.</p>
            </div>
        </section>

        <section class="row">
            <div class="col-12 col-md-4 col-lg-3">
                <img class="img-fluid" src="/assets/img/noti-3.jpg" alt="Placa de noticia">
            </div>
            <div class="col-12 col-md-8 mt-3">
                <h3>Revista Environmental Sustainability</h3>
                <hr>
                <p>Todos los trabajos aceptados tendrán la oportunidad de ser publicados, en formato de artículo científico, en la Special Issue (July-Sept 2022) Plant growth-promoting microorganisms in the New Era: from Ecology to Biotechnology in America, de la revista Environmental Sustainability. Editorial Springer. <a href="https://www.springer.com/journal/42398/updates/19148470">Más información aquí</a></p>
            </div>
        </section>

        <section class="row">
            <div class="col-12 col-md-4 col-lg-3">
                <img class="img-fluid" src="/assets/img/noti-1.jpg" alt="Placa de noticia">
            </div>
            <div class="col-12 col-md-8 col-lg-9 mt-3">
                <h3>Becas</h3>
                <hr>
                <p>Nos complace anunciar que el evento RELAR-PGPR, contará con becas totales para estudiantes cuyos resúmenes hayan sido aceptados.</p>
                <p>  La Asociación Latinoamericana de Rizobiología ALAR otorgará hasta 20 becas para estudiantes y jóvenes investigadores socios de la ALAR que presenten trabajos destacados.</p>
                <p> Complementariamente, el evento otorgará un número limitado de becas otorgadas por la organización del congreso, que serán destinadas a participantes no socios de la ALAR que presenten trabajos destacados y no dispongan de fondos para cubrir los costos de inscripción.</p>
                <p>Para ambos casos, requisitos:</p>
                <ul>
                    <li>Inscribirse a uno de los dos tipos de becas</li>
                    <li>Presentar un trabajo como primer autor o autor de correspondencia</li>
                    <li>Estar dispuesto a participar de todas las instancias del congreso</li>
                    <li>En caso de postular a beca ALAR, presentar un comprobante de que la persona y/o tutor/a son socios de la asociación.ALAR</li>
                </ul>
            </div>
        </section>

    </div>
</article>
