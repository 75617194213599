
<header>
    <!-- TÍTULO -->
    <h2 class="m-3 m-lg-5 p-3 pointer" (click)="volver()">{{ 'Posterhall' | uppercase }}</h2>
    
    <!-- MENU -->
    <nav class="d-flex align-items-center px-3 px-lg-5">
        <div class="menu p-3 pointer" (click)="volver()"><i class="fas fa-angle-left mx-3"></i>Volver</div>
    </nav>
</header>

<section class="bg-white m-md-5 p-3 p-md-5" *ngIf="trabajo">
    <div class="text-end">
        <p class="d-none d-md-block">{{ data.temas[trabajo.tema] | uppercase }}</p>
    </div>

    <!-- PDF -->
    <div *ngIf="trabajo.pdf" class="d-none d-sm-block">
        <embed [src]="trabajo.pdf | safe:'resourceUrl'" class="w-100" height="620" alt="Poster">
    </div>
    <!-- JPG SMALL DEVICES -->
    <div *ngIf="trabajo.jpg&&trabajo.pdf" class="d-sm-none">
        <img [src]="trabajo.jpg" alt="Poster" class="img-fluid">
    </div>
    <!-- VIDEO SIN PDF -->
    <div *ngIf="!trabajo.pdf&&trabajo.mp4">
        <video class="w-100 max-600" controls preload="none">
            <source [src]="trabajo.mp4" type="video/mp4">
        </video>
    </div>
    <!-- PDF BUTTON -->
    <div *ngIf="trabajo.pdf" class="d-flex justify-content-end mt-3">
        <a [href]="trabajo.pdf" target="_blank"><button mat-button>ABRIR PDF</button></a>
    </div>

    <h3 class="mt-3" [ngClass]="{'mt-5': !trabajo.pdf }">{{ trabajo.titulo }}</h3>
    <hr>
    <div class="d-flex flex-wrap mt-3">
        <!-- DATA -->        
        <div class="col-12 col-lg-6">
            <p><strong>Autores: </strong>{{ trabajo.autores }}</p>
            <p><strong>Expone: </strong>{{ trabajo.expone }}</p>
            <p><strong>Instituciones: </strong>{{ trabajo.institucion }}</p>
            <p><strong>Contacto: </strong>{{ email }}</p>
            <hr class="d-lg-none">
        </div>
        <!-- VIDEO -->
        <div *ngIf="trabajo.pdf&&trabajo.mp4" class="col-12 col-lg-6 pl">
            <video controls preload="none" class="w-100 max-320"> <!-- autoplay -->
                <source [src]="trabajo.mp4" type="video/mp4">
            </video>
        </div>
    </div>
    <hr>
    <div class="d-lg-flex">
        <p class="d-inline col-lg-6"><strong>Resumen: </strong>{{ trabajo.resumen | slice:0:dividirTexto(trabajo.resumen) }}</p>
        <p class="d-inline col-lg-6 pl">{{ trabajo.resumen | slice:dividirTexto(trabajo.resumen) }}</p>
    </div>
    <!--<app-foro [tid]="tid"></app-foro>-->
</section>