<div class="p-3 p-md-5 d-flex justify-content-center">
  <div class="bg-white d-inline-block">
    <ngx-auth-firebaseui-login
    [titleText]="'LOGIN.TITLE-TEXT'|translate"
    orLabelText="O"
    [dontHaveAnAccountText]="'LOGIN.DONT-HAVE-AN-ACCOUNT'|translate"
    [createAccountButtonText]="'LOGIN.CREATE-ACCOUNT-BUTTON'|translate"
    [emailErrorRequiredText]="'LOGIN.EMAIL-ERROR-REQUIRED'|translate"
    [emailErrorPatternText]="'LOGIN.EMAIL-ERROR-PATTERN'|translate"
    [passwordErrorRequiredText]="'LOGIN.PASSWORD-ERROR-REQUIRED'|translate"
    [emailText]="'LOGIN.EMAIL'|translate"
    [passwordText]="'LOGIN.PASSWORD'|translate"
    [loginButtonText]="'LOGIN.LOGIN-BUTTON'|translate"
    [forgotPasswordText]="'LOGIN.LOGIN-FORGOT-PASSWORD'|translate"
    [providers]="[providers.Google, providers.Facebook]"
    [resetPasswordEnabled]="true"
    (onResetPasswordRequested)="resetPassword()"
    (onCreateAccountRequested)="registrarse()"
    (onSuccess)="saveUser($event)"
    (onError)="handleError($event)">
    </ngx-auth-firebaseui-login>
  </div>
</div>
