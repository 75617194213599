import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-introduccion',
  templateUrl: './introduccion.component.html',
  styleUrls: ['./introduccion.component.css']
})
export class IntroduccionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
