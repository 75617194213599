<section class="bg-0 pb-sm-2 w-100">
  <!-- TEXTO -->
  <div (click)="registrarse()" class="letras-container puntero py-4">
    <div class="bg-texto-2"></div>
  </div>

  <div class="pb-5 width-lg-95 d-md-flex">

    <!-- PRECIOS -->
    <div id="inscripciones col-12 col-md-6">
      <img class="img-fluid w-100" src="/assets/img/inscripciones.jpg" alt="Información de inscripciones">
    </div>

    <!-- BOTONES -->
    <div class="col-12 col-md-6 d-flex flex-column justify-content-center">
      <div class="w-75 align-self-end puntero mt-5">
        <a (click)="registrarse()">
          <img class="img-fluid w-100" src="/assets/img/boton-1.png" alt="Registrarse">
        </a>
      </div>
    </div>
    
  </div>
</section>
