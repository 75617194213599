import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
