<section class="p-3 p-md-5 m-md-5">
    <h2 class="py-3 pt-md-0">PREGUNTAS FRECUENTES</h2>
    <div *ngFor="let items of faq" class="my-3">
        <mat-accordion>
            <mat-expansion-panel hideToggle *ngFor="let item of items">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ item.pregunta }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div [innerHTML]="item.respuesta" class="justify"></div>
            </mat-expansion-panel>
          </mat-accordion>
    </div>
</section>