<!-- LARGE DEVICES -->
<nav *ngIf="layout.currentScreenSize==='large'">
    <div class="display-flex">
        <ul class="d-flex-center">
            <!--<li class="border-right" (click)="vivo()"><i class="fas fa-circle vivo"></i>{{ 'VIVO' }}</li>-->
            <li class="border-right" [routerLink]="['/home']">{{ 'NAV.HOME' | translate }}</li>
            <li class="border-right" (click)="pdf()">{{ 'LIBRO DE RESÚMENES' }}</li>
            <li class="border-right" [routerLink]="['/videos']">{{ 'CHARLAS MAGISTRALES' }}</li>
            <li class="border-right" [routerLink]="['/programa']">{{ 'NAV.PROGRAMA' | translate }}</li>
            <li class="border-right" [matMenuTriggerFor]="sesiones">{{ 'NAV.SESIONES' | translate }}</li>
            <li class="border-right" [matMenuTriggerFor]="mesas">{{ 'MESAS REDONDAS' }}</li>
            <!--<li class="border-right" [routerLink]="['/apertura']">{{ 'APERTURA' }}</li>-->
            <!--<li class="border-right" [routerLink]="['/clausura']">{{ 'CLAUSURA' }}</li>-->
            <li class="border-right" [routerLink]="['/posterhall']">{{ 'POSTERHALL' }}</li>
            <li class="border-right" [routerLink]="['/noticias']">{{ 'NAV.NOTICIAS' | translate }}</li>
            <li class="border-right" [routerLink]="['/faq']">{{ 'FAQ' }}</li>

            <mat-menu #sesiones="matMenu">
                <div class="bg-white">
                    <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/microbioma-agrícola']">El microbioma agrícola</button>
                    <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/promoción-indirecta-del-crecimiento-vegetal']">Promoción indirecta del crecimiento vegetal</button>
                    <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/promoción-directa-del-crecimiento-vegetal']">Promoción directa del crecimiento vegetal</button>
                    <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/evolución-y-diversidad-de-mpcv']">Evolución y diversidad de microorganismos promotores de crecimiento vegetal</button>
                    <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/el-holobionte-planta']">El Holobionte Planta</button>
                    <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/conservación-y-manejo-microbiano']">Conservación y manejo microbiano en sistemas naturales y agroecosistemas</button>
                    <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/del-laboratorio-al-campo']">Del laboratorio al campo</button>
                    <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/legislación-sobre-productos-microbianos']">Legislación sobre registro y empleo de productos microbianos</button>
                </div>
            </mat-menu>
            <mat-menu #mesas="matMenu">
                <div class="bg-white">
                    <button mat-menu-item [routerLink]="['/mesas-redondas/mujeres-en-la-ciencia']">Mujeres en la ciencia</button>
                    <button mat-menu-item [routerLink]="['/mesas-redondas/bioinsumos']">Actores involucrados en el desarrollo y aplicación de bioinsumos: ¿Es posible un modelo alternativo de producción?</button>
                </div>
            </mat-menu>
        </ul>
        <ul class="d-flex-center flex-grow-1 justify-content-end">
            <!--<li (click)="useLanguage('_es')">{{ 'NAV.ES' | translate }}</li>
            <li (click)="useLanguage('_en')">{{ 'NAV.EN' | translate }}</li>
            <li (click)="useLanguage('_pt')">{{ 'NAV.PT' | translate }}</li>-->
            <li><a href="https://www.facebook.com/relar.pgpr" target="_blank"><i class="fab fa-facebook"></i></a></li>
            <li><a href="https://twitter.com/RelarPgpr" target="_blank"><i class="fab fa-twitter"></i></a></li>
            <li><a href="https://instagram.com/relar.pgpr" target="_blank"><i class="fab fa-instagram"></i></a></li>
            <li><a href="mailto:secretaria.alar@gmail.com"><i class="fas fa-envelope"></i></a></li>
            <li *ngIf="this.auth.user.uid" [routerLink]="['/user']">
                <!--<img [src]="this.auth.user.photoURL | img " alt="Imagen de usuario">-->
                <span matBadge="!" [matBadgeHidden]="auth.user.inscripcion==5" matBadgeColor="warn">{{ this.auth.user.displayName | username }}</span>
            </li>
            <li *ngIf="!this.auth.user.uid">
                <i class="fas fa-user mx-2"></i>
                <span [routerLink]="['/login']">{{ 'NAV.LOGIN' | translate }}</span>
                <span [routerLink]="['/registrarse-1']">/REGISTRARSE</span>
            </li>
        </ul>
    </div>
</nav>

<!-- SMALL DEVICES-->
<nav *ngIf="layout.currentScreenSize!=='large'" class="d-flex-center">
    <button mat-icon-button [matMenuTriggerFor]="menu"><i class="fas fa-bars"></i></button>

    <mat-menu #menu="matMenu">
        <div class="bg-white">
            <!--<button mat-menu-item (click)="vivo()">{{ 'VIVO ' }}<i class="fas fa-circle vivo"></i></button>-->
            <button mat-menu-item [routerLink]="['/home']">{{ 'NAV.HOME' | translate }}</button>
            <button mat-menu-item (click)="pdf()">{{ 'LIBRO DE RESÚMENES' }}</button>
            <!--<button mat-menu-item [routerLink]="['/programa']">{{ 'NAV.PROGRAMA' | translate }}</button>-->
            <button mat-menu-item [routerLink]="['/videos']">{{ 'CHARLAS MAGISTRALES' }}</button>
            <button mat-menu-item [routerLink]="['/apertura']">{{ 'APERTURA' }}</button>
            <button mat-menu-item [matMenuTriggerFor]="sesiones">{{ 'NAV.SESIONES' | translate }}</button>
            <button mat-menu-item [matMenuTriggerFor]="mesas">{{ 'MESAS REDONDAS' }}</button>
            <button mat-menu-item [routerLink]="['/clausura']">{{ 'CLAUSURA' }}</button>
            <button mat-menu-item [routerLink]="['/posterhall']">{{ 'POSTERHALL' }}</button>
            <button mat-menu-item [routerLink]="['/noticias']">{{ 'NAV.NOTICIAS' | translate }}</button>
            <button mat-menu-item [routerLink]="['/faq']">{{ 'FAQ' }}</button>
            <button mat-menu-item [routerLink]="['/user']">{{ 'USUARIO' }}</button>
        </div>
    </mat-menu>

        <mat-menu #sesiones="matMenu">
            <div class="bg-white">
                <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/microbioma-agrícola']">El microbioma agrícola</button>
                <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/promoción-indirecta-del-crecimiento-vegetal']">Promoción indirecta del crecimiento vegetal</button>
                <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/promoción-directa-del-crecimiento-vegetal']">Promoción directa del crecimiento vegetal</button>
                <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/evolución-y-diversidad-de-mpcv']">Evolución y diversidad de microorganismos promotores de crecimiento vegetal</button>
                <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/el-holobionte-planta']">El Holobionte Planta</button>
                <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/conservación-y-manejo-microbiano']">Conservación y manejo microbiano en sistemas naturales y agroecosistemas</button>
                <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/del-laboratorio-al-campo']">Del laboratorio al campo</button>
                <button mat-menu-item [routerLink]="['/sesiones-de-trabajo/legislación-sobre-productos-microbianos']">Legislación sobre registro y empleo de productos microbianos</button>
            </div>
        </mat-menu>
        <mat-menu #mesas="matMenu">
            <div class="bg-white">
                <button mat-menu-item [routerLink]="['/mesas-redondas/mujeres-en-la-ciencia']">Mujeres en la ciencia</button>
                <button mat-menu-item [routerLink]="['/mesas-redondas/bioinsumos']">Actores involucrados en el desarrollo y aplicación de bioinsumos: ¿Es posible un modelo alternativo de producción?</button>
            </div>
        </mat-menu>

</nav>

<!-- Workaround NAV offset -->
<div class="nav-fix"></div>
