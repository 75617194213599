<section>
  <div class="d-flex p-3 p-md-5">
    <div class="col puntero px-5 d-flex align-items-center">
      <img class="img-fluid w-100" [routerLink]="['/noticias']" src="/assets/img/pop-up.png" alt="Noticia 1">
    </div>
    <div class="col puntero p-0">
      <img class="img-fluid w-100" [routerLink]="['/noticias']" src="/assets/img/noti-2.jpg" alt="Noticia 2">
    </div>
    <div class="col puntero px-5 d-flex align-items-center">
      <img class="img-fluid w-100" [routerLink]="['/noticias']" src="/assets/img/noti-4.jpg" alt="Noticia 1">
    </div>
  </div>
</section>
