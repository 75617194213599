<div class="pt-5 col-12 col-md-10 m-auto">

    <div class="mb-5 d-flex flex-row-reverse d-flex flex-wrap">

        <!-- USUARIO -->
        <div class="col-12 col-md-4 p-3">
            <div class="bg-white mb-3 p-md-3">
                <ngx-auth-firebaseui-user
                signoutButtonText="Cerrar sesión"
                editButtonText="Editar"
                saveChangesButtonText="Guardar cambios"
                cancelButtonText="Cancelar"
                notLoggedInText="Usted no está logueado"
                emailVerifiedText="Email verificado"
                emailNotVerifiedText="Email no verificado"
                [canDeleteAccount]="false"
                (onSignOut)="signOut()">
                </ngx-auth-firebaseui-user>
            </div>
        </div>
        
        <div class="col-12 col-md-8 p-3">
            
            <!-- AVISO -->
            <div *ngIf="auth.user.inscripcion<5">
                <div class="jumbotron jumbotron-fluid bg-white text-center p-3 p-md-5">
                    <h2>Inscripción incompleta</h2>
                    <hr>
                    <p class="lead">Su inscripción está pendiente de pago</p>
                    <button mat-raised-button color="primary" class="min-width-50 mb-3" (click)="inscripcion()">
                        Completar inscripcion
                    </button>
                </div>
            </div>
    
            <div *ngIf="auth.user.inscripcion==5">
                <div class="jumbotron jumbotron-fluid bg-white text-center p-3 p-md-5">
                    <h2 *ngIf="auth.user.metodo=='beca'">Beca aceptada</h2>
                    <h2 *ngIf="auth.user.metodo!=='beca'">Inscripción completa</h2>
                    <hr>
                    <p class="lead">Su inscripción se ha completado correctamente</p>
                </div>
            </div>

            <!-- TRABAJOS PRESENTADOS -->
            <div *ngIf="auth.logged&&trabajosLoadded&&trabajos.length>0">
                <div class="bg-white p-3 p-md-5 my-5">
                    <h2>Trabajos presentados</h2>
                    <hr>

                    <div *ngFor="let trabajo of trabajos">
                        <app-cargar-pdf [trabajo]="trabajo" (submittedEvent)="changeSubmitted($event)"></app-cargar-pdf>
                        <hr>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>


