<!-- PROGRESSBAR -->
<ul id="progressbar" class="pt-5 px-0">
    <li class="active" [routerLink]="['/registrarse-1']" id="account"><strong>Cuenta</strong></li>
    <li class="active" [routerLink]="['/registrarse-2']" class="active" id="personal"><strong>Información Personal</strong></li>
    <li class="active" [routerLink]="['/registrarse-3']" class="active" id="postular"><strong>Postular trabajo</strong></li>
    <li class="active" id="payment"><strong>Pago</strong></li>
    <li id="confirm"><strong>Finalizar</strong></li>
</ul>
<div class="bg-white p-3 p-md-5 my-md-5 mx-auto w-lg-75">
    <div class="d-flex justify-content-between align-items-end">
        <h3 class="mb-0">Pago</h3>
        <div>
            <span class="mx-2">Saltar este paso</span>
            <button mat-mini-fab color="primary" (click)="saltarPago()" aria-label="Terminar inscripción sin pagar">
                <i class="fas fa-arrow-right"></i>
            </button>
        </div>
    </div>
    <hr>Le recordamos que el pago de la inscripción no es requisito necesario para postular un trabajo.
    <hr>Por cualquier consulta puede comunicarse a nuestro e-mail: <a class="text-black" href="mailto:secretaria.alar@gmail.com">secretaria.alar@gmail.com</a>
    <hr>
    <div class="row">
        <div class="col">
            <div class="text-center">
                <img class="img-fluid" src="/assets/img/pago/paypal.png" alt="Paypal logo">
                <app-paypal></app-paypal>
            </div>
        </div>
        <!-- BROU -->
        <div class="col">
            <div class="text-center">
                <img class="img-fluid" src="/assets/img/pago/ebrou.png" alt="eBrou logo">
                <button mat-raised-button color="primary" class="w-100 p-1 p-lg-2" (click)="showEbrou=!showEbrou">
                    Continuar con transferencia
                </button>
            </div>
            <div *ngIf="showEbrou" class="p-3 my-3 brou">
                <p>BANCO DE LA REPÚBLICA ORIENTAL DEL URUGUAY</p>
                <p>Domicilio: Piedras 369, Montevideo-Uruguay.</p>
                <p>Titular de cuenta: FUNDACIÓN DE APOYO INST. CLEMENTE ESTABLE (FAICE)</p>
                <p>Cuentas Corrientes BROU: U$S 001568027-00002</p>
                <p>Desde otros bancos 00156802700002</p>
                <p>CódigoSwift: BROUUYMMXXX</p>
                <p>RELAR - PGPR no se hace cargo de los costos de transferencia imputados por otros bancos.</p>
                <p>Agradecemos enviar comprobante de transferencia a <a class="text-white" href="mailto:secretaria.alar@gmail.com">secretaria.alar@gmail.com</a></p>
                <form autocomplete="off" (ngSubmit)="save(f)" #f="ngForm">
                    <div class="row my-3 mx-0">
                        <input  class="form-control"
                                type="text"
                                name="number"
                                placeholder="Número de transferencia"
                                [class.is-invalid]="number.invalid && number.touched"
                                [ngModel]="brou.number"
                                required
                                minlength="2"
                                #number="ngModel">
                        <small *ngIf="number.invalid && number.touched" class="form-text text-danger">
                            El número de transferencia es obligatorio
                        </small>
                    </div>
                    <div class="row mx-0">
                        <textarea class="form-control"
                                type="text"
                                name="text"
                                placeholder="NOTAS|Campo no obligatorio"
                                [ngModel]="brou.text"
                                maxlength="256"
                                #text="ngModel">
                        </textarea>
                        <button mat-raised-button color="primary" type="submit" class="w-100 mt-3">
                            Registrar pago
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="bg-white mb-md-5 mx-auto w-lg-75">
    <hr class="m-0">
    <div class="d-flex align-items-center p-3">
        <div class="w-75 px-2 text-end">
            Si usted tiene un código de inscripción, por favor ingréselo aquí:
        </div>
        <div class="input-group w-25">
            <input class="form-control" type="password" #pass>
            <button class="btn btn-primary" (click)="freePass(pass.value)" type="button"><i class="fas fa-arrow-right"></i></button>
        </div>
    </div>
</div>
