<main class="pt-md-5">
  <!-- HEADER -->
  <app-header></app-header>

  <div class="d-none d-md-block">
    <!-- NOTICIAS
    <app-home-noticias></app-home-noticias>
    -->
    <!-- FRENTE-1 -->
    <app-home-introduccion></app-home-introduccion>
    <!-- FECHAS IMPORTANTES -->
    <app-home-fechas-importantes></app-home-fechas-importantes>
  </div>

  <!--INSCRIPCIONES -->
  <app-home-inscripciones></app-home-inscripciones>

  <div class="d-none d-md-block">
    <!-- MESAS DE TRABAJO -->
    <app-home-mesas></app-home-mesas>
    <!-- POSTER TALKS -->
    <app-home-poster></app-home-poster>
    <!-- ACERCA DE ALAR -->
    <app-home-acerca></app-home-acerca>
    <!-- COMITÉS -->
    <app-home-comite></app-home-comite>
    <!-- LOGOS -->
    <app-home-logos></app-home-logos>
  </div>
</main>